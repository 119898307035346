/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.4/dist/jquery.min.js
 * - /npm/jquery-form@3.50.0/jquery.form.min.js
 * - /npm/jquery-modal@0.9.2/jquery.modal.min.js
 * - /npm/angular@1.5.5/angular.min.js
 * - /npm/angular-cookies@1.5.5/angular-cookies.min.js
 * - /npm/angular-animate@1.5.5/angular-animate.min.js
 * - /npm/angular-resource@1.5.5/angular-resource.min.js
 * - /npm/iframe-resizer@4.3.6/js/iframeResizer.min.js
 * - /npm/iframe-resizer@4.3.6/js/iframeResizer.contentWindow.min.js
 * - /npm/noty@3.2.0-beta/lib/noty.min.js
 * - /npm/swiper@10.3.1/swiper-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
